// Entry point for build scripts.

import * as ReactImp from "react";
import * as ReactDOMServerImp from "react-dom/server";
import * as ReactDOMClientImp from "react-dom/client";
import * as componentsImp from "./Nora.Components";

globalThis.React = ReactImp;
globalThis.ReactDOMServer = ReactDOMServerImp;
(globalThis as any).ReactDOMClient = ReactDOMClientImp;
(globalThis as any).Components = componentsImp;

// Create-react-app requires the index file to export something.
export default {};
